export default [
  {
    name: "firstName",
    required: true,
    label: "First Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.firstName
  },
  {
    name: "lastName",
    required: true,
    label: "Last Name",
    align: "left",
    sortable: true,
    field: (i: any) => i.lastName
  },
  {
    name: "projects",
    required: true,
    label: "Projects",
    align: "left",
    sortable: false,
    field: (i: any) =>
      i.intakeFormSubmissions.map((s: any) => s.project.title).join(", ")
  },
  {
    name: "userResponsible/FirstName",
    required: true,
    label: "Assigned To",
    align: "left",
    sortable: true,
    field: (i: any) =>
      i.userResponsible
        ? `${i.userResponsible.firstName} ${i.userResponsible.lastName}`
        : ""
  },
  {
    name: "dateOfBirth",
    required: true,
    label: "Date of Birth",
    align: "left",
    sortable: true,
    field: (i: any) =>
      i.dateOfBirth ? new Date(Date.parse(i.dateOfBirth)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "gender/value",
    required: true,
    label: "Gender",
    align: "left",
    sortable: true,
    field: (i: any) => i.gender?.value
  },
  {
    name: "nationality/value",
    required: true,
    label: "Nationality",
    align: "left",
    sortable: true,
    field: (i: any) => i.nationality?.value
  },
  {
    name: "IdentityNo",
    required: true,
    label: "identity Number",
    align: "left",
    sortable: true,
    field: (i: any) => i.identityNo
  },
  {
    name: "PassportNo",
    required: true,
    label: "Passport Number",
    align: "left",
    sortable: true,
    field: (i: any) => i.passportNo
  },
  {
    name: "AmkaNo",
    required: true,
    label: "AMKA Number",
    align: "left",
    sortable: true,
    field: (i: any) => i.amkaNo
  },
  {
    name: "Ric",
    required: true,
    label: "RIC Number",
    align: "left",
    sortable: true,
    field: (i: any) => i.ric
  },
  {
    name: "Paaypa",
    required: true,
    label: "PAAYPA Number",
    align: "left",
    sortable: true,
    field: (i: any) => i.paaypa
  },
  {
    name: "AfmNo",
    required: true,
    label: "AFM Number",
    align: "left",
    sortable: true,
    field: (i: any) => i.afmNo
  },
  {
    name: "legalStatus/value",
    required: true,
    label: "Legal Status",
    align: "left",
    sortable: true,
    field: (i: any) => i.legalStatus?.value
  },
  {
    name: "socialNumber",
    required: true,
    label: "Social Number",
    align: "left",
    sortable: true,
    field: (i: any) => i.socialNumber
  },
  {
    name: "phoneNumber",
    required: true,
    label: "Phone Number",
    align: "left",
    sortable: true,
    field: (i: any) => i.phoneNumber
  },
  {
    name: "email",
    required: true,
    label: "Email",
    align: "left",
    sortable: true,
    field: (i: any) => i.email
  },
  {
    name: "otherContactDetails",
    required: true,
    label: "Other Contact Details",
    align: "left",
    sortable: true,
    field: (i: any) => i.otherContactDetails
  },
  {
    name: "status",
    required: true,
    label: "Status",
    align: "left",
    sortable: true,
    field: (i: any) => i.status
  }
];
