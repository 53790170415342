








































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import { Location } from "vue-router";
import DataProvider from "@/lib/DataProvider";
import { HouseholdMember } from "@/data/models/HouseholdMember";
import ICrudClient from "@/lib/ICrudClient";
import FindColumns from "@/data/columns/FindHouseHoldMembers";

@Component({
  components: {
    ModelTable
  }
})
export default class BeneficiariesFindTable extends Vue {
  @Prop()
  private provider!: DataProvider<HouseholdMember>;

  @Prop()
  public crud!: ICrudClient<HouseholdMember>;

  @Prop()
  public columns!: any[];

  @Prop()
  public viewRoute!: Location;

  @Prop()
  public addRoute!: Location;

  private tableProvider = this.provider;
  private findColumns = FindColumns;

  private copyDialog = false;
  private filters = {
    firstNameFilter: "",
    lastNameFilter: "",
    dateOfBirthFilter: "",
    nationalityFilter: "",
    emailFilter: "",
    phoneNumberfilter: "",
    identityNo: "",
    passportNo: "",
    amkaNo: "",
    afmNo: "",
    ric: "",
    otherContactDetails: "",
    assignedTo: ""
  };

  private navigateToRoute(route: (id: string) => Location, id: any) {
    if (!route) {
      return;
    }
    this.$router.push(route(id));
  }

  private filterMembers() {
    const filterArr = [];
    if (this.filters.firstNameFilter) {
      filterArr.push(`contains(FirstName, '${this.filters.firstNameFilter}')`);
    }
    if (this.filters.lastNameFilter) {
      filterArr.push(`contains(LastName, '${this.filters.lastNameFilter}')`);
    }
    if (this.filters.dateOfBirthFilter) {
      filterArr.push(
        `contains(cast(DateOfBirth, Edm.String), '${this.filters.dateOfBirthFilter}')`
      );
    }
    if (this.filters.nationalityFilter) {
      filterArr.push(
        `contains(Nationality/Value, '${this.filters.nationalityFilter}')`
      );
    }
    if (this.filters.emailFilter) {
      filterArr.push(`contains(Email, '${this.filters.emailFilter}')`);
    }
    if (this.filters.phoneNumberfilter) {
      filterArr.push(
        `contains(PhoneNumber, '${this.filters.phoneNumberfilter}')`
      );
    }
    if (this.filters.identityNo) {
      filterArr.push(`contains(IdentityNo, '${this.filters.identityNo}')`);
    }
    if (this.filters.passportNo) {
      filterArr.push(`contains(PassportNo, '${this.filters.passportNo}')`);
    }
    if (this.filters.amkaNo) {
      filterArr.push(`contains(AmkaNo, '${this.filters.amkaNo}')`);
    }
    if (this.filters.afmNo) {
      filterArr.push(`contains(AfmNo, '${this.filters.afmNo}')`);
    }
    if (this.filters.ric) {
      filterArr.push(`contains(Ric, '${this.filters.ric}')`);
    }
    if (this.filters.otherContactDetails) {
      filterArr.push(
        `contains(OtherContactDetails, '${this.filters.otherContactDetails}')`
      );
    }
    if (this.filters.assignedTo) {
      filterArr.push(
        `Prescreening/AssignedToUser ne null and 
          (contains(Prescreening/AssignedToUser/FirstName, '${this.filters.assignedTo}') or 
           contains(Prescreening/AssignedToUser/LastName, '${this.filters.assignedTo}'))`
      );
    }
    return filterArr.join(" and ");
  }

  @Watch("filters", { deep: true })
  async onFiltersChange() {
    const filters = this.filterMembers();
    this.tableProvider = await this.$service.providers.householdMembersFilters(
      filters
    );
  }
}
